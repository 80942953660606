exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-barber-program-js": () => import("./../../../src/pages/barber-program.js" /* webpackChunkName: "component---src-pages-barber-program-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-consumer-disclosures-js": () => import("./../../../src/pages/consumer-disclosures.js" /* webpackChunkName: "component---src-pages-consumer-disclosures-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cosmetology-program-js": () => import("./../../../src/pages/cosmetology-program.js" /* webpackChunkName: "component---src-pages-cosmetology-program-js" */),
  "component---src-pages-financial-aid-js": () => import("./../../../src/pages/financial-aid.js" /* webpackChunkName: "component---src-pages-financial-aid-js" */),
  "component---src-pages-full-specialist-program-js": () => import("./../../../src/pages/full-specialist-program.js" /* webpackChunkName: "component---src-pages-full-specialist-program-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-net-price-calc-html-js": () => import("./../../../src/pages/index-net-price-calc.html.js" /* webpackChunkName: "component---src-pages-index-net-price-calc-html-js" */),
  "component---src-pages-instructor-program-js": () => import("./../../../src/pages/instructor-program.js" /* webpackChunkName: "component---src-pages-instructor-program-js" */),
  "component---src-pages-manicure-program-js": () => import("./../../../src/pages/manicure-program.js" /* webpackChunkName: "component---src-pages-manicure-program-js" */),
  "component---src-pages-net-price-calculator-js": () => import("./../../../src/pages/net-price-calculator.js" /* webpackChunkName: "component---src-pages-net-price-calculator-js" */),
  "component---src-pages-restricted-barber-program-js": () => import("./../../../src/pages/restricted-barber-program.js" /* webpackChunkName: "component---src-pages-restricted-barber-program-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-skin-care-program-js": () => import("./../../../src/pages/skin-care-program.js" /* webpackChunkName: "component---src-pages-skin-care-program-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-va-benefits-js": () => import("./../../../src/pages/va-benefits.js" /* webpackChunkName: "component---src-pages-va-benefits-js" */)
}

